import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-portal',
  template: `
    <div class=container>
      <p>Redirecting...</p>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, CommonModule],
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent {
  constructor(private router: Router) { }
  ngOnInit() {
    this.router.navigate(['/'])
  }
}
