import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-intro',
  template: `
    <div class="container intro flex flex-space-between">
      <div class="intro__lead">
        <h1 style="font-family: monospace"class="intro__title">octotech.cloud</h1>
        <p class="intro__text">Innovative bot integration</p>
        <div class="intro__links">
          <a routerLink="/contact-us" class="button primary button--large">
            Contact Us
          </a>
        </div>
      </div>
      <img
        class="image"
        width="450px" 
        height="450px"
        src="./assets/chatbot.svg"  
        alt="chatbot"
      />
      <p>Elevate your customer engagement and streamline communication with octotech.cloud's bot integration solutions. Contact us today to learn more about how we can transform your business. Together, we'll build a future where communication is intelligent, seamless, and customer-centric.</p>

    </div>
  `,
  styles: [
    `
      .intro {
        img {
          height: auto;
        }
        &.container {
          @media screen and (max-width: 1220px) {
            text-align: center;
            justify-content: center;
          }
        }
        &__title {
          font-size: 2.5rem;
          font-weight: 600;
          @media screen and (max-width: 850px) {
            & {
              font-size: 2rem;
            }
          }
        }
        &__links {
          margin-top: 40px;
        }

        &__lead {
          margin: 20px;
          max-width: 650px;
          font-size: 1.3rem;
          @media screen and (max-width: 850px) {
            & {
              font-size: 1rem;
            }
          }
        }
        &__info {
          font-size: 0.9rem;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule]
})
export class IntroComponent {}
