import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SeoService } from '../_core/services/seo.service';
import { IntroComponent } from './intro/intro.component';

@Component({
  selector: 'app-home',
  template: `
    <section class="section intro">
        <app-intro></app-intro>
    </section>
  `,
  standalone: true,
  imports: [CommonModule, IntroComponent]
})
export class HomeComponent implements OnInit {
  constructor(private seo: SeoService) { }

  ngOnInit() {
    this.seo.setText('octotech.cloud');
  }
}
