import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  template: `
    <footer>
      <div style="padding:10px" class="hide-for-small">
        <div class="container flex flex-space-between">
          <span>OCTO TECH LLC </span>
          <span>2232 Dell Range Blvd STE 245-3468 Cheyenne, WY 82009</span>
          <span><a href="mailto:info@octotech.cloud">info@octotech.cloud</a></span>
          <span><a href="tel:+13072009330">+1 (307) 200 - 9330 </a></span>
        </div>
      </div>
    </footer>
  `,
  styles: [
    `
      footer {
        font-size: 0.75rem;
        background-color: var(--bg-transparent);
        border-top: 1px solid var(--border-color);
        margin-top: -1px;
        padding: 0 20px;
        text-align: right;
        .flex {
          @media screen and (max-width: 450px) {
            justify-content: center;
            margin: auto;
            padding: 0px;
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class FooterComponent { }
